<template>
  <div class="admin-panel-page">
    <v-row justify="center">
      <v-col md="6">
        <v-card
          elevation="0"
          class="py-6 px-4 mt-md-2 mx-md-2 welcome-card"
          :style="{
            background: $vuetify.theme.themes.light.primary,
          }"
        >
          <h2 class="white--text">Hello! Welcome to Admin Panel</h2>
        </v-card>
        <!-- analytics card -->
        <!-- <v-slide-y-transition mode="out-in">
          <v-card
            outlined
            v-show="showCard"
            elevation="0"
            class="bg-analytics mx-2 mx-md-2 my-2 px-4 py-4"
          >
            <h3>Analytics</h3>
            <p class="ma-0 font-weight-medium">Data is updated every hour.</p>
          </v-card>
        </v-slide-y-transition> -->
        <v-slide-y-transition mode="out-in">
          <div v-if="counts">
            <v-row justify="center" dense class="mx-1 mt-2">
              <v-col cols="4" class="text-center">
                <v-card
                  outlined
                  elevation="0"
                  class="py-5"
                  :to="{ name: 'AdminStudentsList' }"
                >
                  <h1 class="primary--text">{{ counts.total_students }}</h1>
                  <p class="body-2 font-weight-bold ma-0">Students</p>
                </v-card>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-card
                  outlined
                  elevation="0"
                  class="py-5"
                  :to="{ name: 'AdminStaffList' }"
                >
                  <h1 class="primary--text">{{ counts.total_staff }}</h1>
                  <p class="body-2 font-weight-bold ma-0">Staff</p>
                </v-card>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-card
                  outlined
                  elevation="0"
                  class="py-5"
                  :to="{ name: 'AdminClassroomsList' }"
                >
                  <h1 class="primary--text">{{ counts.total_rooms }}</h1>
                  <p class="body-2 font-weight-bold ma-0">Classrooms</p>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-slide-y-transition>

        <!-- <v-slide-y-transition mode="out-in">
          <div class="mx-2 my-3" v-if="showAnalytics">
            <v-card
              non-focused
              outlined
              elevation="0"
              class="py-5 bg-analytics"
              :to="{ name: 'Analytics' }"
            >
              <div class="ml-3">
              <h2 class="primary--text">Analytics</h2>
              <p class="ma-0">See what's going on in your institution</p>
              </div>
            </v-card>
          </div>
        </v-slide-y-transition> -->

        <div class="mx-2 my-3" v-if="showAnalytics">
          <v-card
            non-focused
            outlined
            elevation="0"
            class="py-5"
            :to="{ name: 'KrafAnalytics' }"
          >

              <div class="ml-3">
              <h2 class="primary--text">Analytics
                  <v-badge
                    bordered
                    color="primary"
                    content="new"
                    class="ml-3"
                  >
                  </v-badge>
              </h2>
              <p class="ma-0">See what's going on in your school</p>
              </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
     <v-slide-y-transition>
      <v-btn
        style="z-index: 100; bottom: 72px"
        class="extended-fab non-focused"
        color="primary"
        v-if="!isLoading && showFab"
        :href="`https://wa.me/+${currentInstitution.preferences.custom_support_number && currentUser.is_student?
                                 currentInstitution.preferences.custom_support_number : 
                                '919175915234'
                                }?text=Hi! I'm ${currentUser.full_name} from ${currentInstitution.name}. I had an issue with the App...`"
        target="_blank"
        dark
        small
        right
        bottom
        fixed
        fab
      >
        <v-img class="mr-2" width="28px" src="@assets/images/WhatsApp.png" />
        WhatsApp Support
      </v-btn>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Mixins from "@utils/mixins";
export default {
  name: "AdminPanel",
  mixins: [Mixins.essentials],
  data() {
    return {
      showFab: false,
      showCard: false,
      showAnalytics: false,
      counts: null,
    };
  },
  methods: {
    ...mapActions(["changeAppBarVisibility"]),
    async getCounts() {
      this.counts = await this.api.call(
        this.essentials,
        this.endpoints.adminPanelCounts
      );
    },
  },
  destroyed() {
    this.changeAppBarVisibility(true);
  },
  async created() {
    this.changeAppBarVisibility(false);
    var self = this;
    setTimeout(() => (self.showCard = true), 100);
    setTimeout(() => (self.showFab = true), 400);
    setTimeout(() => (self.showAnalytics = true), 250);
    setTimeout(self.getCounts, 100);
  },
};
</script>
<style scoped>
.bg-analytics {
  background-image: url("../../assets/images/analytics_background_image.png");
  background-position: center;
  background-size: cover;
}
</style>